<template>
  <div class="bg-gray-10">
    <div
      class="max-w-wrapper-lg m-auto py-10 lg:py-20 px-4 lg:px-0"
      :class="[wrapperClass]"
    >
      <component
        :is="headerTag"
        class="text-2.5xl md:text-4.5xl lg:text-center mb-4 md:mb-2 lg:mb-6"
        :class="[headerTitleClass]"
      >
        <slot name="header"></slot>
      </component>

      <p class="text-lg lg:text-center mb-6 lg:mb-10 max-w-wrapper-md mx-auto">
        <slot name="sub-header"></slot>
      </p>

      <div
        class="-mx-4 px-4 pb-4"
        :class="{
          'overflow-x-scroll xl:overflow-x-hidden': !noScroll
        }"
      >
        <div
          class=" gap-8 mx-auto"
          :class="[
            {
              'grid': !noScroll,
              'flex flex-col': noScroll,
            },
            !noScroll ? gridCount : '',
            !noScroll ? cardWrapperMaxWidth : ''
          ]"
        >
          <atoms-card
            v-for="(card, cardKey) in cards"
            :key="cardKey"
            shadow="bordered"
            class="flex flex-col text-left min-w-[13rem] p-6"
            size="md"
            :class="[cardClass]"
          >
            <NuxtImg
              v-if="card.icon"
              :src="card.icon"
              :alt="card.title"
              :width="card.iconDimensions && card.iconDimensions.width"
              :height="card.iconDimensions && card.iconDimensions.height"
              :class="card.iconClass"
              :loading="imageLazyLoad ? 'lazy' : 'eager'"
              class="mx-auto w-16 h-16"
            />

            <!-- eslint-disable vue/no-v-html -->
            <p
              v-if="card.title"
              class="text-lg font-bold text-center"
              :class="[
                {
                  'mt-4': card.icon,
                  'mb-2': card.description
                },
                titleClass
              ]"
              v-html="card.title"
            >
            </p>

            <p
              v-if="card.description"
              class="text-base text-center flex-1"
              :class="[
                {
                  'mb-8': card.url
                },
                descriptionClass
              ]"
              v-html="card.description"
            >
            </p>

            <atoms-button
              v-if="card.url"
              class="mx-auto text-xl"
              size="large"
              theme="tertiary"
              :to="card?.url"
              :class="[
                buttonConfig?.class,
                {
                  'mt-6': !card.description
                }
              ]"
            >
              {{ buttonConfig?.label || 'LEARN MORE' }}
            </atoms-button>
          </atoms-card>
        </div>
      </div>

      <div
        v-if="$slots.footer"
        :class="footerClass"
        class="mt-10"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'HomeGreatDeal'
})

const props = defineProps({
  cards: {
    type: Array,
    required: true,
    validator: () => ([])
  },

  headerTag: {
    type: String,
    default: 'p',
    validator: value => {
      return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div'].includes(value)
    }
  },

  colCount: {
    type: Number,
    default: 3,
    validator: value => [1, 2, 3, 4].includes(value)
  },

  imageLazyLoad: {
    type: Boolean,
    default: false
  },

  cardWrapperMaxWidth: {
    type: String,
    default: 'w-wrapper-md'
  },

  cardClass: {
    type: String,
    default: ''
  },

  titleClass: {
    type: String,
    default: ''
  },

  descriptionClass: {
    type: String,
    default: ''
  },

  buttonConfig: {
    type: Object,
    default: () => ({
      class: '',
      label: 'LEARN MORE'
    })
  },

  noScroll: {
    type: Boolean,
    default: false
  },

  footerClass: {
    type: String,
    default: ''
  },

  headerTitleClass: {
    type: String,
    default: ''
  },

  wrapperClass: {
    type: String,
    default: ''
  }
})

const gridCount = computed(() => {
  const count = props.colCount

  return getKey(count, {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4'
  })
})
</script>
